import { cx } from '@linaria/core';
import gql from 'graphql-tag';

import { makeMemoFragment } from '../../../utils/graphql';
import { isValidList } from '../../../utils/contentUtils';
import { darkContentBlockListStyles, StyledContentBlockList as ContentBlockList } from '../Types/ContentBlockList.jsx';
import { darkMainTopicStyles, mainTopicFragment, StyledMainTopic as MainTopic } from '../TopicCluster/MainTopic.jsx';
import { linkWithArrowFragment, StyledLinkWithArrow as LinkWithArrow } from '../LinkWithArrow.jsx';
import { useTranslation } from '../../hooks/useTranslation.jsx';

export const programInfoFragment = makeMemoFragment({
  name: 'ProgramInfo',
  fragment() {
    return gql`fragment ${this.name} on Content {
      ... on PlaybackResourceAspect {
        containsMultipleContributions
      }
      ... on AssociationsAspect {
        unifiedPrograms(amount: 1) {
          title
          teaser
          name
          ...${linkWithArrowFragment.name}
          ...${mainTopicFragment.name}
        }
      }
    }
    ${linkWithArrowFragment.fragment()}
    ${mainTopicFragment.fragment()}
    `;
  },
});

const makeBottomLinkFn = content => () => ((
  <LinkWithArrow
    content={content}
    linkTranslation={{
      key: 'content_block_detail.program_info.headline.program_link',
      parameters: { programName: content.name },
    }} />
));

export const ProgramInfo = ({ className, content }) => {
  const unifiedProgram = content.unifiedPrograms[0];

  const aboutProgramInfoTitleTranslation = useTranslation({
    key: 'content_block_detail.program_info.headline.label',
    parameters: { programTitle: unifiedProgram?.title },
  });

  if (!content.containsMultipleContributions || !isValidList(content.unifiedPrograms)) {
    return null;
  }

  return (
    <ContentBlockList
      className={className}
      headlineIsA='h2'
      headlineTranslation='content_block_detail.program_info.headline.content_block'
      bottomElementFn={makeBottomLinkFn(unifiedProgram)}
      pageSectionId='program-info'
    >
      <MainTopic
        content={unifiedProgram}
        topicTitleSelector={c => c.title}
        topicTeaserSelector={c => c.teaser}
        aboutTopicTitleTranslation={aboutProgramInfoTitleTranslation}
      />
    </ContentBlockList>
  );
};

export const darkProgramInfoStyles = cx(
  darkContentBlockListStyles,
  darkMainTopicStyles,
);

export const StyledProgramInfo = ProgramInfo;
