import { css, cx } from '@linaria/core';

import { socialMediaToIcon } from '../../utils/socialBookmarks';
import { ButtonWithIconOnly } from '../buttons/Button';
import { LinkBase } from '../Links/LinkBase';

export const SocialMediaIconLink = ({
  socialMediaId,
  className,
  isHidden,
  title,
  url,
  ...restProps
}) => {
  const SocialMediaIconTag = socialMediaToIcon[socialMediaId];
  if (!SocialMediaIconTag) {
    return null;
  }
  const propsBeingValidHtmlForNotLazy = isHidden ? { isHidden } : {};
  return (
    <ButtonWithIconOnly className={cx(className, socialMediaId, socialMediaIconLinkStyles)}
      isA={LinkBase}
      to={url}
      title={title}
      role="link"
      {...restProps}
    >
      <SocialMediaIconTag {...propsBeingValidHtmlForNotLazy} />
    </ButtonWithIconOnly>
  );
};

export const darkSocialMediaIconLinkStyles = ButtonWithIconOnly.darkStyles;

const socialMediaIconLinkStyles = css`
  svg {
    width: 100%;
    path {
      stroke-width: 0;
    }
  }

`;
