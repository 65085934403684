import { BrowserOnly } from '../BrowserOnly.jsx';
import { suspendedComponentFactory } from '../SuspendedComponent/suspendedComponentFactory.jsx';

// @VisibleForTesting
// Important hint: Make sure to use null as the fallback here, as using anything else may cause bugs: RWD-4337
export const { SlideChangeTracking: LazySlideChangeTracking } = suspendedComponentFactory(() => import('./Lazy/SlideChangeTracking.jsx'), { fallback: null });

export const EnableAsyncSlideChangeTracking = ({ shouldPush, slideEventData, content }) => (
  <BrowserOnly>
    <LazySlideChangeTracking shouldPush={shouldPush} slideEventData={slideEventData} content={content} />
  </BrowserOnly>
);
