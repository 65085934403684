import { ButtonWithIconOnly } from '../buttons/Button';
import { StyledSearch } from '../icons';
import { LinkBase } from '../Links/LinkBase';
import { useParams } from '../../dwouter';
import { useTranslation } from '../hooks/useTranslation.jsx';
import { generatePaginatedPath } from '../Pagination.jsx';

export const SearchButton = ({ className }) => {
  const { langCode } = useParams();
  const searchLabel = useTranslation('search.search_access.search_button.label');
  const linkTarget = generatePaginatedPath({ url: `/${langCode}/search`, pageNum: 1 });

  return (
    <ButtonWithIconOnly
      className={className}
      isA={LinkBase}
      to={linkTarget}
      aria-label={searchLabel}
      title={searchLabel}
      rel="nofollow"
      data-tracking-name="go-to-search"
    >
      <StyledSearch />
    </ButtonWithIconOnly>
  );
};
