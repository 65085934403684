import gql from 'graphql-tag';

import { cx } from '@linaria/core';
import { NotFoundPage } from '../../../pages/NotFoundPage.jsx';
import { StyledHeaderWithTheme as Header, headerFragmentFactory } from '../../Header';
import { StyledFooter as Footer, footerFragmentFactory } from '../../Footer';
import { StyledCoCoPageHeadlineInHeader as CoCoPageHeadlineInHeader, coCoPageHeadlineInHeaderFragment } from '../CoCoPageHeadlineInHeader.jsx';
import { AutoTopicCoCo, autoTopicCoCoFragment } from './AutoTopicCoCo.jsx';
import { StyledQuickNavigationList as QuickNavigationList } from '../../a11y/QuickNavigationList.jsx';
import { PremiumGptSlot } from '../../GptSlot';
import { overviewPremiumSlotConfig } from '../../GptSlot/slotConfigs';

import { PageHeaderMetadata, pageHeaderMetadataFragment } from '../../PageHeaderMetadata.jsx';
import { BrowserOnlyBreakingBanner } from '../../BreakingBanner';
import { makeMemoFragment } from '../../../utils/graphql';
import { useParams } from '../../../dwouter';
import { AutoTopicContentsPaginated, PAGE_SIZE, autoTopicContentsPaginatedFragment } from './AutoTopicContentsPaginated.jsx';
import { getTotalPages } from '../../AutoTopicPagination.jsx';
import { useTranslations } from '../../hooks/useTranslation.jsx';
import { withTheme } from '../../higherOrderComponents/withTheme.jsx';

export const autoTopicFragment = makeMemoFragment({
  name: 'AutoTopicWithFallback',
  fragment() {
    const headerFragment = headerFragmentFactory();
    const footerFragment = footerFragmentFactory();
    return gql`fragment ${this.name} on AutoTopic {
        name
        description
        relatedContentsPaginated(offset: $offset, amount: ${PAGE_SIZE}) {
          totalHits
        }
        ...${pageHeaderMetadataFragment.name}
        ...${coCoPageHeadlineInHeaderFragment.name}
        ...${autoTopicCoCoFragment.name}
        ...${autoTopicContentsPaginatedFragment.name}
        ...${headerFragment.name}
        ...${footerFragment.name}
      }
      ${pageHeaderMetadataFragment.fragment()}
      ${coCoPageHeadlineInHeaderFragment.fragment()}
      ${autoTopicCoCoFragment.fragment()}
      ${autoTopicContentsPaginatedFragment.fragment()}
      ${headerFragment.fragment()}
      ${footerFragment.fragment()}
     `;
  },
});

const isValidPageNumber = ({ pageNumber, totalPages }) => pageNumber > 0 && pageNumber <= totalPages;

export const AutoTopicWithFallback = ({ autoTopic, className }) => {
  const { pageNumber } = useParams();
  const totalPages = getTotalPages(autoTopic.relatedContentsPaginated.totalHits, PAGE_SIZE);

  const [
    paginatedPageTitle,
    paginatedPagePositionLabel,
  ] = useTranslations([
    'content_block.atp_all_items_list.headline',
    {
      key: 'component.pagination.paging.label',
      parameters: { currentPage: pageNumber, totalPages },
    },
  ]);

  const hasValidPageNumber = isValidPageNumber({ pageNumber, totalPages });

  if (!hasValidPageNumber && (!autoTopic.contentComposition || pageNumber)) {
    return (
      <NotFoundPage/>
    );
  }

  const title = hasValidPageNumber ? [autoTopic.title, paginatedPageTitle, paginatedPagePositionLabel].join(' \u2013 ') : autoTopic.title;
  return (
    <>
      <PageHeaderMetadata content={{ ...autoTopic, title }}/>
      <QuickNavigationList content={autoTopic}/>
      <Header content={autoTopic}>
        {({ isHeaderInAlternateState, isForcedDarkMode }) => (isHeaderInAlternateState
          ? (
            <CoCoPageHeadlineInHeader className={isForcedDarkMode && CoCoPageHeadlineInHeader.darkStyles} navigation={autoTopic} />
          )
          : null
        )}
      </Header>
      <div className={className}>
        <BrowserOnlyBreakingBanner />
        <PremiumGptSlot slotConfig={overviewPremiumSlotConfig}/>
        { hasValidPageNumber
          ? <AutoTopicContentsPaginated autoTopic={autoTopic} />
          : <AutoTopicCoCo autoTopic={autoTopic} />
        }
      </div>
      <Footer content={autoTopic}/>
    </>
  );
};

const darkAutoTopicWithFallbackStyles = cx(
  AutoTopicContentsPaginated.darkStyles,
  AutoTopicCoCo.darkStyles,
);

AutoTopicWithFallback.darkStyles = darkAutoTopicWithFallbackStyles;

export const AutoTopicWithFallbackWithTheme = withTheme(AutoTopicWithFallback)(AutoTopicWithFallback.darkStyles);
