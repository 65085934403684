import gql from 'graphql-tag';
import { cx } from '@linaria/core';

import { StyledBasicLayout as BasicLayout } from './layouts/BasicLayout';
import { articleTopSlotConfig, articleContentBottomSlotConfig } from './GptSlot/slotConfigs';
import {
  articleDetailHeaderFragment,
  darkArticleDetailHeaderStyles,
  StyledArticleDetailHeader as ArticleDetailHeader,
} from './ContentDetailHeader/ArticleDetailHeader.jsx';
import {
  darkOpinionDetailHeaderStyles,
  opinionDetailHeaderFragment,
  StyledOpinionDetailHeader as OpinionDetailHeader,
} from './ContentDetailHeader/OpinionDetailHeader.jsx';
import {
  darkMediaDetailHeaderStyles,
  mediaDetailsHeaderFragment,
  StyledMediaDetailsHeader as MediaDetailsHeader,
} from './ContentDetailHeader/MediaDetailsHeader.jsx';
import {
  contentDetailRichTextFragment,
  StyledContentDetailRichText as ContentDetailRichText,
} from './ContentDetailRichText';
import {
  contentDetailFooterFragment,
  darkContentDetailFooterStyles,
  StyledContentDetailFooter as ContentDetailFooter,
} from './ContentDetailFooter';
import { partialFragments } from './ContentTeaserSnippets/ContentTeaserPartials';
import { makeMemoFragment } from '../utils/graphql';
import {
  darkObservedContentDetailActionStyles,
  ObservedContentDetailActions,
  observedContentDetailActionsFragment,
} from './ObservedContentDetailActions.jsx';
import { StyledDetailPageHeroImage as DetailPageHeroImage, detailPageHeroImageFragment } from './DetailPageHeroImage.jsx';
import {
  BeforeRichTextGptSlot,
  AfterRichTextGptSlot,
} from './GptSlot';
import { livestreamContentHeroFragment, LivestreamContentHero } from './LivestreamContentHero.jsx';

export const articleDetailsFragment = makeMemoFragment({
  name: 'ArticleDetails',
  fragment() {
    return gql`fragment ${this.name} on Article {
            hasMinTextLength
            isLiveVideo
            ...${detailPageHeroImageFragment.name}
            ...${articleDetailHeaderFragment.name}
            ...${opinionDetailHeaderFragment.name}
            ...${mediaDetailsHeaderFragment.name}
            ...${contentDetailRichTextFragment.name}
            ...${contentDetailFooterFragment.name}
            ...${observedContentDetailActionsFragment.name}
            ...${partialFragments.opinionFragment.name}
            ...${livestreamContentHeroFragment.name}
        }
        ${detailPageHeroImageFragment.fragment()}
        ${articleDetailHeaderFragment.fragment()}
        ${opinionDetailHeaderFragment.fragment()}
        ${mediaDetailsHeaderFragment.fragment()}
        ${contentDetailRichTextFragment.fragment()}
        ${contentDetailFooterFragment.fragment()}
        ${observedContentDetailActionsFragment.fragment()}
        ${partialFragments.opinionFragment.fragment()}
        ${livestreamContentHeroFragment.fragment()}
    `;
  },
});

const ArticleDetailsPartials = {
  Default: {
    DetailHeader: ArticleDetailHeader,
    hasHeroImg: true,
  },
  Opinion: {
    DetailHeader: OpinionDetailHeader,
  },
  LiveVideo: {
    HeroCmpOverwrite: LivestreamContentHero,
    DetailHeader: MediaDetailsHeader,
  },
};

const getArticleDetailsPartials = ({ content }) => {
  const { isOpinion = false, isLiveVideo = false } = content;
  if (isLiveVideo) {
    return ArticleDetailsPartials.LiveVideo;
  }
  if (isOpinion) {
    return ArticleDetailsPartials.Opinion;
  }
  return ArticleDetailsPartials.Default;
};

export const ArticleDetails = ({ content = {}, className }) => {
  const { DetailHeader, HeroCmpOverwrite, hasHeroImg } = getArticleDetailsPartials({ content });
  const { hasMinTextLength } = content;

  return (
    <BasicLayout
      className={className}
      HeroCmp={HeroCmpOverwrite}
      heroCmpProps={HeroCmpOverwrite && {
        content,
      }}
    >
      <DetailHeader content={content}/>
      <ObservedContentDetailActions content={content} />
      {hasHeroImg && <DetailPageHeroImage content={content} />}
      <BeforeRichTextGptSlot slotConfig={articleTopSlotConfig}/>
      <ContentDetailRichText content={content}/>
      <ContentDetailFooter content={content}/>
      {hasMinTextLength &&
      <AfterRichTextGptSlot slotConfig={articleContentBottomSlotConfig}/>
      }
    </BasicLayout>
  );
};

export const darkArticleDetailsStyles = cx(
  darkArticleDetailHeaderStyles,
  darkOpinionDetailHeaderStyles,
  darkMediaDetailHeaderStyles,
  darkObservedContentDetailActionStyles,
  ContentDetailRichText.darkStyles,
  darkContentDetailFooterStyles,
  AfterRichTextGptSlot.darkStyles,
);

export const StyledArticleDetails = ArticleDetails;
