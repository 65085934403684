import gql from 'graphql-tag';
import { css, cx } from '@linaria/core';
import { InView } from 'react-intersection-observer';
import { colors } from '../../../utils/css';
import { makeMemoFragment } from '../../../utils/graphql';
import { PageHeadline } from '../../PageHeadline.jsx';
import { StyledA11yLinkTargetMarker as A11yLinkTargetMarker } from '../../a11y/A11yLinkTargetMarker.jsx';
import { ProgramList, programListFragment } from '../../ContentBlocks/ProgramList';
import { ids } from '../../../utils/staticElementIds';
import { useHeaderColorToggle } from '../../hooks/useHeaderColorToggle';
import { useTopOffsetHeaderThreshold } from '../../hooks/useTopOffsetHeaderThreshold';

export const programsOverviewFragment = makeMemoFragment({
  name: 'ProgramsOverviewCoCo',
  fragment() {
    return gql`fragment ${this.name} on ProgramsOverview {
      videoPrograms {
        ...${programListFragment.name}
      }
      audioPrograms {
        ...${programListFragment.name}
      }
      pageHeadline
    }
    ${programListFragment.fragment()}`;
  },
});

export const ProgramsOverviewCoCo = ({
  content,
  className,
}) => {
  const {
    pageHeadline,
    videoPrograms,
    audioPrograms,
  } = content;
  const { onChangeInViewListenerForHeaderSwitch, forcedOffsetParentForHeaderToggleClassName } = useHeaderColorToggle();
  const topOffsetHeaderThreshold = useTopOffsetHeaderThreshold();
  return (
    <A11yLinkTargetMarker
      className={cx(programsOverviewCoCoClass, forcedOffsetParentForHeaderToggleClassName, className)}
      id={ids.quickNav.mainContent}
    >
      <InView as="div" onChange={onChangeInViewListenerForHeaderSwitch} rootMargin={topOffsetHeaderThreshold}>
        <PageHeadline>{pageHeadline}</PageHeadline>
      </InView>
      <ProgramList pageSectionId='program-video-list' headlineTranslation={'content_block.program_list_videos.headline'} contents={videoPrograms} />
      <ProgramList pageSectionId='program-audio-list' headlineTranslation={'content_block.program_list_audios.headline'} contents={audioPrograms} />
    </A11yLinkTargetMarker>
  );
};

export const programsOverviewCoCoClass = css`
  section {
    background-color: ${colors.DARK_BLUE_GREY_01};
  }
  section:nth-of-type(2n) {
    background-color: ${colors.DARK_BLUE_GREY_02};
  }
`;

ProgramsOverviewCoCo.darkStyles = cx(
  PageHeadline.darkStyles,
  ProgramList.darkStyles,
);
