export const resolutions = Object.freeze({
  min: Object.freeze({
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    wide_xl: 1600,
  }),
  max: Object.freeze({
    xs: 575,
    sm: 767,
    md: 991,
    lg: 1199,
    xl: 1599,
    wide_xl: Number.MAX_SAFE_INTEGER,
  }),
});

const wrapInMedia = (sizes, type) => Object.keys(sizes).reduce((acc, label) => (
  {
    ...acc,
    ...{
      // eslint-disable-next-line fp/no-rest-parameters
      [label]: (...args) => `
        @media (${type}-width:${sizes[label]}px) {
          ${String.raw(...args)}
        }
      `,
    },
  }
), {});

export const mediaMin = wrapInMedia(resolutions.min, 'min');
export const mediaMax = wrapInMedia(resolutions.max, 'max');

// eslint-disable-next-line fp/no-rest-parameters
const wrapInHoverPointerMedia = (hover, pointer) => (...args) => `
  @media (hover: ${hover}) and (pointer: ${pointer})  {
    ${String.raw(...args)}
  }
`;

export const aspectRatioFormats = Object.freeze({
  wide: '16/7',
  ultraWide: '21/9',
  square: '1/1',
  squarish: '4/3',
});

const wrapInAspectRatioAndResolution = ({
  resolutionType, size, aspectRatioType, aspectRatio,
  /* eslint-disable-next-line fp/no-rest-parameters */
}) => (...args) => `
  @media (${resolutionType}-width:${size}px) and (${aspectRatioType}-aspect-ratio: ${aspectRatio}) {
    ${String.raw(...args)}
  }

`;

/* https://medium.com/@ferie/detect-a-touch-device-with-only-css-9f8e30fa1134 */
/* https://media.kulturbanause.de/2017/05/interaction-type-media-queries.html */
export const mediaDevice = {
  mouse: wrapInHoverPointerMedia('hover', 'fine'),
  touch: wrapInHoverPointerMedia('none', 'coarse'),
};

export const aspectRatioAware = {
  xl: {
    ultraWide: wrapInAspectRatioAndResolution({
      size: resolutions.min.xl,
      resolutionType: 'min',
      aspectRatioType: 'min',
      aspectRatio: aspectRatioFormats.wide,
    }),
  },
};

export const colors = Object.freeze({
  WHITE: '#FFF',
  BLACK: '#000',

  // NEW design
  DW_DARK_BLUE: '#002186',
  DW_LIGHT_BLUE: '#05B2FC',
  DW_YELLOW: '#FAE000',
  BLUE_GREY_01: '#F0F6FA',
  BLUE_GREY_02: '#CDE1EE',
  BLUE_GREY_03: '#99B5C9',
  BLUE_GREY_04: '#5C718A',
  BLUE_GREY_05: '#445D7B',
  DARK_BLUE_GREY_01: '#081336',
  DARK_BLUE_GREY_02: '#000821',
  WARM_GREY_01: '#F4F2F2',
  WARM_GREY_02: '#E0DFDE',
  WARM_GREY_03: '#CFCCCA',
  ACCENT_GREEN: '#63DE9D',
  ACCENT_RED: '#EF6C6C',
  BREAKING_RED: '#BE232D',
});

export const selector = Object.freeze({
  composition: Object.freeze({
    even: ':first-child:nth-last-child(2n)',
    odd: ':first-child:nth-last-child(2n+1)',
    _369: ':first-child:nth-last-child(3n)',
    _258: ':first-child:nth-last-child(3n+2)',
    _4710: ':first-child:nth-last-child(3n+1)',
  }),
  notFirst: ':not(:first-child)',
  notLast: ':not(:last-child)',
  disabled: '.disabled',
  topElements: numberOfElements => `:nth-child(-n+${numberOfElements})`,
  justNElements: numberOfElements => `:first-child:nth-last-child(${numberOfElements})`,
});

export const hexToRgba = (hex, alpha = 1) => {
  const [r, g, b] = hex.match(hex.length <= 4 ? /\w/g : /\w\w/g).map(x => parseInt(x.length < 2 ? `${x}${x}` : x, 16));
  return `rgba(${r},${g},${b},${alpha})`;
};

export const makeBreakpointVars = cssVarName => Object.keys(resolutions.min)
  .reduce((acc, next) => ({ ...acc, [next]: `${cssVarName}-${next}` }), {});
