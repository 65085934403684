import { css, cx } from '@linaria/core';
import { LinkBase } from '../Links/LinkBase';
import { ButtonTertiarySmall } from '../buttons/Button';
import { useLocation } from '../../dwouter';
import { getLangCodeFromUrlPath } from '../../utils/url';
import { useHeaderStateContext } from './HeaderStateContext';

export const ChineseToggleButton = ({ alternateUrls, className }) => {
  const [{ pathname }] = useLocation();
  const langCode = getLangCodeFromUrlPath(pathname);
  const alternateUrl = alternateUrls.find(url => getLangCodeFromUrlPath(url) !== langCode);
  const { isForcedDarkMode, isHeaderInAlternateState } = useHeaderStateContext();

  return (
    <ButtonTertiarySmall
      isA={LinkBase}
      className={cx(
        className,
        chineseToggleButtonClass,
        isForcedDarkMode && isHeaderInAlternateState && ButtonTertiarySmall.darkStyles,
      )}
      to={alternateUrl}
      title={langCode === 'zh' ? '點擊此處訪問繁體中文頁面' : '点击此处访问简体中文页面'}
    >
      {langCode === 'zh' ? '繁' : '简'}
    </ButtonTertiarySmall>
  );
};

export const chineseToggleButtonClass = css`
  aspect-ratio: 1;
`;
