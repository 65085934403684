import { styled } from '@linaria/react';
import { mediaMin } from '../utils/css';
import { HeadlineSemiBoldDwDarkBlueAndWhite } from './Text/Headline.jsx';

export const DetailHeaderHeadline = ({
  className,
  children,
}) => {
  if (!children) {
    return null;
  }
  return (
    <HeadlineSemiBoldDwDarkBlueAndWhite isA="h1" className={className}>
      {children}
    </HeadlineSemiBoldDwDarkBlueAndWhite>
  );
};

export const StyledDetailHeaderHeadline = styled(DetailHeaderHeadline)`
    font-size: 3rem;
    margin-bottom: 7px;
    word-wrap: break-word;

    ${mediaMin.md`
      font-size:3.8rem;
    `}

    ${mediaMin.lg`
      font-size: 4.6rem;
    `}
`;

StyledDetailHeaderHeadline.darkStyles = HeadlineSemiBoldDwDarkBlueAndWhite.darkStyles;
