import { replaceWithComponent } from './replaceCommons';
import { mapImgPlaceholderToPicComponent } from './imagesReplacer.jsx';
import { imageGalleryPlaceholderToSlideshowComponentFactory } from './imageGalleryReplacer.jsx';
import { widgetPlaceholderToWidgetComponent } from './widgetReplacer.jsx';
import { videoPlaceholderToVideoComponentFactory } from './videoReplacer.jsx';
import { audioPlaceholderToAudioComponentFactory } from './audioReplacer.jsx';
import { mapTwitterPlaceholderToTwitterComponent } from './twitterReplacer.jsx';
import { mapYoutubePlaceholderToYoutubeComponent } from './youtubeReplacer.jsx';
import { mapInstagramPlaceholderToInstagramComponent } from './instagramReplacer.jsx';
import { mapFacebookPlaceholderToFacebookComponent } from './facebookReplacer.jsx';
import { mapTelegramPlaceholderToTelegramComponent } from './telegramReplacer.jsx';
import { mapTikTokPlaceholderToTikTokComponent } from './tikTokReplacer.jsx';
import { mapAdPlaceholderToGptSlot } from './gptSlotReplacer.jsx';

export const replaceAllPlaceholders = ({ content, richTextElement }) => [
  replaceWithComponent({
    selector: '.placeholder-image > img',
    richTextElement,
    componentFn: mapImgPlaceholderToPicComponent,
  }),
  replaceWithComponent({
    selector: '.image-gallery',
    richTextElement,
    componentFn: imageGalleryPlaceholderToSlideshowComponentFactory(content),
  }),
  replaceWithComponent({
    selector: 'video',
    richTextElement,
    componentFn: videoPlaceholderToVideoComponentFactory(content),
  }),
  replaceWithComponent({
    selector: 'audio',
    richTextElement,
    componentFn: audioPlaceholderToAudioComponentFactory(content),
  }),
  replaceWithComponent({
    selector: 'blockquote.tweet',
    richTextElement,
    componentFn: mapTwitterPlaceholderToTwitterComponent,
  }),
  replaceWithComponent({
    selector: '.yt-wrapper',
    richTextElement,
    componentFn: mapYoutubePlaceholderToYoutubeComponent,
  }),
  replaceWithComponent({
    selector: '.dw-widget',
    richTextElement,
    componentFn: widgetPlaceholderToWidgetComponent,
  }),
  replaceWithComponent({
    selector: 'blockquote.instagram',
    richTextElement,
    componentFn: mapInstagramPlaceholderToInstagramComponent,
  }),
  replaceWithComponent({
    selector: 'blockquote[data-facebook]',
    richTextElement,
    componentFn: mapFacebookPlaceholderToFacebookComponent,
  }),
  replaceWithComponent({
    selector: 'blockquote.telegram',
    richTextElement,
    componentFn: mapTelegramPlaceholderToTelegramComponent,
  }),
  replaceWithComponent({
    selector: 'blockquote.tiktok',
    richTextElement,
    componentFn: mapTikTokPlaceholderToTikTokComponent,
  }),
  replaceWithComponent({
    selector: '.rich-text-ad',
    richTextElement,
    componentFn: mapAdPlaceholderToGptSlot,
  }),
].flatMap(v => v.portals);
