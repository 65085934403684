import { styled } from '@linaria/react';
import { css, cx } from '@linaria/core';
import { mediaMin } from '../../../utils/css';
import { retrieveLayoutImage } from '../../../utils/imgUtils';
import { StyledResponsiveDwPicture as ResponsiveDwPicture } from '../../ResponsiveDwPicture';
import { mainImageWithCaptionFragment } from '../../ResponsiveDwPicture/mainImageWithCaptionFragment';
import { alwaysCinemascopePictureConfig } from '../../ResponsiveDwPicture/pictureConfigs';
import { contentBlockPadding } from '../ContentBlock';
import { StyledCopyrightInfo as CopyrightInfo, darkCopyrightInfoStyles } from '../../CopyrightInfo.jsx';
import { metaBackground, metaGradient } from '../../../utils/gradientCss';
import { noop } from '../../../utils/commons';
import { StyledHeroImage as HeroImage } from '../../HeroImage/HeroImage.jsx';

export const topicImageWithCustomTitleFragment = mainImageWithCaptionFragment;

export const TopicImageWithCustomTitle = ({
  content,
  className,
  titleSlotFn = noop,
  preload,
}) => {
  const layoutImage = retrieveLayoutImage(content);
  const hasImage = !!layoutImage;
  const titleSlot = titleSlotFn({ hasImage });
  const ImageTag = preload ? HeroImage : ResponsiveDwPicture;

  return (
    <div className={className}>
      {hasImage &&
      <>
        <ImageTag
          image={content.mainContentImageLink.target}
          className="topic-picture"
          alt={layoutImage.alt}
          formatConfig={alwaysCinemascopePictureConfig}
        />
        <div className="topic-gradient" />
        <div className="topic-background"/>
        <CopyrightInfo className="copyright" licenserSupplement={layoutImage.licenserSupplement}/>
        <CopyrightInfo className={cx('copyright', darkCopyrightInfoStyles)} licenserSupplement={layoutImage.licenserSupplement}/>
      </>
      }
      <div className='topic-title'>
        {titleSlot}
      </div>
    </div>
  );
};

export const darkTopicImageWithCustomTitleStyles = css`
  .copyright:not(.${darkCopyrightInfoStyles}) {
    display: none;
  }
  .copyright.${darkCopyrightInfoStyles} {
    display: initial;
  }
`;

export const StyledTopicImageWithCustomTitle = styled(TopicImageWithCustomTitle)`
  display: grid;
  grid-template-rows: repeat(3, min-content);
  grid-template-columns: 100%;

  & > * {
    grid-column: 1;
  }

  .topic-picture {
    grid-row: 1 / 4;
    z-index: 1;
  }

  .topic-gradient {
    ${metaGradient}
    grid-row: 2;
    z-index: 2;
  }

  .topic-background {
    ${metaBackground}
    grid-row: 3;
    z-index: 2;
  }
  *:where(.${darkCopyrightInfoStyles}) {
    display: none;
  }
  .copyright {
    grid-row: 3;
    z-index: 3;
    align-self: end;
    justify-self: end;

    margin-inline-end: 15px;
    margin-bottom: 5px;
  }

  .topic-title {
    grid-row: 2;
    z-index: 3;

    padding-inline: ${contentBlockPadding.xs}px
  }

  ${mediaMin.sm`
    .topic-title {
      padding-inline: ${contentBlockPadding.sm}px;
    }
  `}

  ${mediaMin.md`
    .topic-title {
      padding-inline: ${contentBlockPadding.md}px;
    }
  `}

  ${mediaMin.lg`
    .topic-title {
      padding-inline: ${contentBlockPadding.lg}px;
    }
  `}
`;
