import globals from '../globals';
import { loadScriptPromise } from '../staticResourceHelpers';

const fbApiVersion = 'v21.0';
let hasResizeListener = false; // eslint-disable-line fp/no-let
let loadingApiPromise = null; // eslint-disable-line fp/no-let

const initFacebookApiPromise = () => new Promise(resolve => {
  // HINT: window.FB object is changed after init!
  // We need to pass a fully initialized API object down the chain
  globals.window.fbAsyncInit = () => { // eslint-disable-line fp/no-mutation
    const { FB, document } = globals.window;
    FB.init({ xfbml: true, version: fbApiVersion });
    resolve({ FB: globals.window.FB, document });
  };
});

const retrieveFacebookJsScript = async () => {
  await loadScriptPromise({
    url: 'https://connect.facebook.net/en_US/sdk.js',
    scriptId: 'facebook-sdk-script',
    vendorId: 's7',
  });
  return initFacebookApiPromise();
};

export const resetFacebookApi = () => {
  globals.window.FB = null; // eslint-disable-line fp/no-mutation
  globals.window.fbAsyncInit = null; // eslint-disable-line fp/no-mutation
  hasResizeListener = false; // eslint-disable-line fp/no-mutation
  loadingApiPromise = null; // eslint-disable-line fp/no-mutation
};

export const loadFacebookApi = async () => {
  if (!loadingApiPromise) {
    // eslint-disable-next-line fp/no-mutation
    loadingApiPromise = retrieveFacebookJsScript();
  }
  return loadingApiPromise;
};

export const addResizeEventListener = ({ FB }) => {
  let resizeTimeout = null; // eslint-disable-line fp/no-let
  if (!hasResizeListener) {
    globals.window.addEventListener('resize', () => {
      if (resizeTimeout) {
        globals.window.clearTimeout(resizeTimeout);
      }
      // eslint-disable-next-line fp/no-mutation
      resizeTimeout = globals.window.setTimeout(() => FB.XFBML.parse(), 200);
    });
    hasResizeListener = true; // eslint-disable-line fp/no-mutation
  }
};
