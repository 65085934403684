import gql from 'graphql-tag';
import { styled } from '@linaria/react';
import { css, cx } from '@linaria/core';

import {
  contentDetailRichTextFragment,
  StyledContentDetailRichText as ContentDetailRichText,
} from '../ContentDetailRichText';
import { makeMemoFragment } from '../../utils/graphql';
import { colors, mediaMin } from '../../utils/css';
import { darkLiveblogPostHeaderStyles, liveblogPostHeaderFragment, StyledLiveblogPostHeader as LiveblogPostHeader } from './LiveblogPostHeader.jsx';
import { StyledA11ySection as A11ySection } from '../a11y/A11ySection.jsx';
import { StyledLiveblogPostActions as LiveblogPostActions, liveblogPostActionsFragment, darkLiveblogPostActionsStyles } from './LiveblogPostActions.jsx';
import { liveblogPostPadding } from '../layouts/ContentMoveUpLayout/ContentArea';

export const liveblogPostFragment = makeMemoFragment({
  name: 'LiveblogPost',
  fragment() {
    return gql`fragment ${this.name} on LiveblogElement {
        id
        title
        ...${liveblogPostHeaderFragment.name}
        ...${contentDetailRichTextFragment.name}
        ...${liveblogPostActionsFragment.name}
      }
      ${liveblogPostHeaderFragment.fragment()}
      ${contentDetailRichTextFragment.fragment()}
      ${liveblogPostActionsFragment.fragment()}
    `;
  },
});

export const LiveblogPost = ({ content, className, sectionId }) => {
  const { id, title } = content;
  const pageSectionId = sectionId || `liveblog-post-${id}`;

  return (
    <A11ySection
      className={className}
      pageSectionId={pageSectionId}
      sectionTitle={title}
    >
      <LiveblogPostHeader content={content}/>
      <ContentDetailRichText className="post-rich-text" content={content}/>
      <LiveblogPostActions content={content}/>
    </A11ySection>
  );
};

export const cssVariables = {
  background: '--liveblog-post-background',
};

export const darkLiveblogPostStyles = cx(
  darkLiveblogPostHeaderStyles,
  ContentDetailRichText.darkStyles,
  darkLiveblogPostActionsStyles,
  css`${cssVariables.background}: ${colors.DARK_BLUE_GREY_01};`,
);

const liveblogPostAlignment = `
  .vjs-wrapper {
    background-color: var(${cssVariables.background}, ${colors.WHITE});
    .headline {
      background-color: ${colors.BLACK};
      padding-inline: 15px;
    }
  }

  .image-gallery {
    .headline-container, .headline-background-container, .description-container {
      & > * {
        padding-inline: 15px;
      }
    }
    .headline-container h3 {
      padding-inline: 0;
    }
  }

  ${mediaMin.sm`
    .placeholder-image, .embed {
      padding-inline: ${liveblogPostPadding.sm}px;
    }
  `}

  ${mediaMin.md`
    .placeholder-image, .embed {
      padding-inline: ${liveblogPostPadding.md}px;
    }
  `}

  ${mediaMin.lg`
    .placeholder-image, .embed {
      padding-inline: ${liveblogPostPadding.lg}px;
    }
  `}

  ${mediaMin.xl`
    .placeholder-image, .embed {
      padding-inline: ${liveblogPostPadding.xl}px;
    }
  `}

`;

export const StyledLiveblogPost = styled(LiveblogPost)`
  background-color: var(${cssVariables.background}, ${colors.WHITE});
  scroll-margin-top: 200px;

  .post-rich-text {
    margin-top: 20px;
    ${liveblogPostAlignment}
  }

  .rich-text > *:last-child {
    margin-bottom: 0;
  }
`;

StyledLiveblogPost.darkStyles = darkLiveblogPostStyles;
