import gql from 'graphql-tag';
import { makeMemoFragment } from '../../utils/graphql';

export const trackingDataFragment = makeMemoFragment({
  name: 'TrackingData',
  fragment() {
    return gql`fragment ${this.name} on Content {
      ... on ModelAspect {
        language
      }
      ... on DeliveryAspect {
        subdomain
      }
      ...on GtmAspect {
        trackingId
        trackingLanguageCode
        trackingLanguageKey
        trackingContentType
        trackingPageTypeKey
        trackingCategories
        trackingDate
        trackingPageSID
        trackingTopicsCommaJoined
        trackingRegionsCommaJoined
      }
      ...on NamedAspect {
        name
        title
      }
      ... on MetadataAspect {
        profileTopicContent
      }
      ...on AssociationsAspect {
        subjects {
          id
          name
        }
        categories {
          originId
        }
        departments(amount: 1) {
          id
          name
        }
        regions {
          regionCode
          countryCode
        }
        unifiedPrograms(amount: 1) {
          id
          name
        }
        navigations(amount: 1) {
          id
          idsToRoot
        }
      }
      ... on UrlAspect {
        namedUrl
      }
    }
    `;
  },
});

export const mediaTrackingDataFragment = makeMemoFragment({
  name: 'MediaInfoForTrackingFragment',
  fragment() {
    return gql`fragment ${this.name} on Content {
      ... on PlaybackResourceAspect {
        duration
      }
      ...${trackingDataFragment.name}
    }
    ${trackingDataFragment.fragment()}
    `;
  },
});
