import { css, cx } from '@linaria/core';
import { useMemo } from 'react';
import { makeBreakpointVars, mediaMin } from '../../../utils/css';
import { useSlotSetupEffect } from '../useSlotSetupEffect';
import { useSlotLazyLoadEffect } from '../useSlotLazyLoadEffect';
import { getElementId } from '../slotCommons';
import { BrowserOnly } from '../../BrowserOnly.jsx';
import { getDisplayStyles } from './styleCalc';

export const SingleResolutionGptSlot = ({
  slotConfigEntry,
  className,
}) => {
  const slot = useSlotSetupEffect({ slotConfigEntry });
  useSlotLazyLoadEffect({ slot, slotConfigEntry });
  const displayStyles = useMemo(() => getDisplayStyles({ slotConfigEntry, cssVariables }), [slotConfigEntry]);
  return (
    <div
      id={getElementId(slotConfigEntry)}
      className={cx(responsiveGptSlotClass, className)}
      style={{
        width: Array.isArray(slotConfigEntry.slotSize) ? 'initial' : `${slotConfigEntry.slotSize.width}px`,
        height: Array.isArray(slotConfigEntry.slotSize) ? 'initial' : `${slotConfigEntry.slotSize.height}px`,
        ...displayStyles,
      }}
    />
  );
};

export const SsrCompatibleSingleResolutionGptSlot = ({
  slotConfigEntry,
  className,
}) => {
  const fallback = (
    <div
      id={getElementId(slotConfigEntry)}
      className={className}
    />
  );
  return (
    <BrowserOnly fallback={fallback}>
      <SingleResolutionGptSlot
        className={className}
        slotConfigEntry={slotConfigEntry}
      />
    </BrowserOnly>
  );
};

export const cssVariables = {
  display: makeBreakpointVars('--responsive-gpt-slot-display'),
};

export const responsiveGptSlotClass = css` 
  display: var(${cssVariables.display.xs});
  ${mediaMin.sm`
    display: var(${cssVariables.display.sm});
  `}
  ${mediaMin.md`
    display: var(${cssVariables.display.md});
  `}
  ${mediaMin.lg`
    display: var(${cssVariables.display.lg});
  `}
  ${mediaMin.xl`
   display: var(${cssVariables.display.xl});
  `}
  ${mediaMin.wide_xl`
    display: var(${cssVariables.display.wide_xl});
   `}
`;
