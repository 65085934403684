import { useParams } from '../dwouter';
import { Pagination } from './Pagination.jsx';

export const getTotalPages = (totalHits, pageSize) => Math.ceil(totalHits / pageSize);

export const AutoTopicPagination = ({
  className, totalHits, pageSize, namedUrl,
}) => {
  const { pageNumber } = useParams();
  const currentPageNumber = +pageNumber;
  const totalPages = getTotalPages(totalHits, pageSize);

  return (
    <Pagination
      className={className}
      currentPageNumber={currentPageNumber}
      totalPages={totalPages}
      namedUrl={namedUrl}
    />
  );
};

AutoTopicPagination.darkStyles = Pagination.darkStyles;
