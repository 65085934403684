import classnames from 'classnames';
import gql from 'graphql-tag';
import { css, cx } from '@linaria/core';
import { liveTvLinkFragment, StyledLiveTvLink as LiveTvLink } from '../buttons/LiveTvLink';
import { mediaGroupFragment, StyledMediaGroup as MediaGroup } from '../navigation/MediaNavigation/MediaGroup';
import { focusMenuFragment, StyledFocusMenu as FocusMenu } from './FocusMenu';
import { useHeaderStateContext } from './HeaderStateContext';
import { makeMemoFragment } from '../../utils/graphql';
import { SearchButton } from './SearchButton.jsx';
import { useFrontendConfig } from '../FrontendConfigContext.jsx';
import { isValidList } from '../../utils/contentUtils';
import { ChineseToggleButton } from './ChineseToggleButton.jsx';
import { mediaMin } from '../../utils/css';

export const headerQuickNavigationItemsFragmentFactory = () => makeMemoFragment(({
  name: 'HeaderQuickNavigationItems',
  fragment() {
    return gql`fragment ${this.name} on Navigation {
        ...${mediaGroupFragment.name}
        ...${focusMenuFragment.name}
        defaultChannel {
          ...${liveTvLinkFragment.name}
        }
      }
      ${mediaGroupFragment.fragment()}
      ${focusMenuFragment.fragment()}
      ${liveTvLinkFragment.fragment()}
    `;
  },
}));

export const HeaderQuickNavigationItems = ({
  topStoriesNavigation, alternateUrls,
}) => {
  const { isHeaderInAlternateState, isNavigationVisible } = useHeaderStateContext();
  const { showSearch } = useFrontendConfig() || {};

  return (
    <>
      { !isHeaderInAlternateState &&
        <FocusMenu
          parentClassName="focus-menu"
          content={topStoriesNavigation}
        />
      }
      <div className={cx('quick-navigation-items', headerQuickNavigationItemsStyles)}>
        { !isHeaderInAlternateState &&
          <>
            <MediaGroup
              className={classnames('media-group', { invisible: isNavigationVisible })}
              navigation={topStoriesNavigation}
            />
            <LiveTvLink
              channel={topStoriesNavigation?.defaultChannel}
              className="live-tv-btn"
            />
          </>
        }
        { isValidList(alternateUrls) && (
          <ChineseToggleButton
            alternateUrls={alternateUrls}
            className="chinese-toggle-btn"
          />
        )}
        { !isHeaderInAlternateState && showSearch &&
          <SearchButton className="search-btn" />
        }
      </div>
    </>
  );
};

export const headerQuickNavigationItemsStyles = css`
  display: flex;
  column-gap: 7px;
  align-items: center;
  z-index: 4;

  .search-btn {
    width: 40px;
  }

  .media-group {
    &.invisible {
      visibility: hidden;
    }
  }

  ${mediaMin.sm`
    column-gap: 8px;
  `}

  ${mediaMin.lg`
    column-gap: 9px;
  `}

  ${mediaMin.xl`
    column-gap: 10px;
  `}
`;
