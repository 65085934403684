import { useInView } from 'react-intersection-observer';
import globals from '../utils/globals';
import { INTERSECTION_OBSERVER_SETTINGS } from './LazyLoadDwPicture.jsx';
import { useLoadTikTokApiEffect, extractTikTokVideoIdFromUrl } from '../utils/tikTok';
import { StyledCmpPreviewCompatiblePlaceholder as CmpPreviewCompatiblePlaceholder } from './CmpPreviewCompatiblePlaceholder.jsx';

const cmpPreviewClass = 'tiktok-oembed';
// globals.window not defined during the transformation of the linaria/wyw-in-js plugin
if (globals.window) {
  // eslint-disable-next-line fp/no-mutation
  globals.window.cmp_preview_vendor_s2536_class = `.${cmpPreviewClass}`;
}

export const TikTokPost = ({ url, className }) => {
  const [inViewRef, inView] = useInView(INTERSECTION_OBSERVER_SETTINGS);
  useLoadTikTokApiEffect({ inView });
  const videoId = extractTikTokVideoIdFromUrl(url);

  return (
    <CmpPreviewCompatiblePlaceholder className={className} forwardRef={inViewRef}>
      <blockquote className={`${cmpPreviewClass} tiktok-embed`} cite={url} data-video-id={videoId}>
        <section />
      </blockquote>
    </CmpPreviewCompatiblePlaceholder>
  );
};
