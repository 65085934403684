import { styled } from '@linaria/react';
import {
  contentDetailRichTextFragment,
  StyledContentDetailRichText as ContentDetailRichText,
} from '../ContentDetailRichText';
import { StyledInfobox as Infobox } from '../Infobox.jsx';
import { StyledContentBlock as ContentBlock } from '../ContentBlocks/ContentBlock';

export const liveblogRichTextFragment = contentDetailRichTextFragment;

export const LiveblogRichText = ({ content, className }) => (
  <ContentBlock
    isA={Infobox}
    className={className}
    headlineTranslation="content_block_detail.live_blog_overview.headline"
    headlineIsA="h2"
    pageSectionId="live-blog-rich-text-overview"
  >
    <ContentDetailRichText content={content} />
  </ContentBlock>
);

export const darkLiveblogRichTextStyles = ContentDetailRichText.darkStyles;

export const StyledLiveblogRichText = styled(LiveblogRichText)`
  margin: 20px -15px 50px;
  padding-block: 0;
`;
