import globals from '../globals';
import { loadScriptPromise } from '../staticResourceHelpers';

const waitForTikTokScriptInitialized = callback => {
  if (globals.window.tiktokEmbed?.isEventsInit) {
    callback({ tiktokEmbed: globals.window.tiktokEmbed });
  } else {
    setTimeout(() => {
      waitForTikTokScriptInitialized(callback);
    }, 100);
  }
};

const retrieveTikTokJsScript = async () => {
  await loadScriptPromise({
    url: 'https://www.tiktok.com/embed.js',
    scriptId: 'tiktok-embed-script',
    vendorId: 's2536',
  });
  return new Promise(resolve => {
    waitForTikTokScriptInitialized(resolve);
  });
};

// eslint-disable-next-line fp/no-let
let loadingApiPromise = null;

// @VisibleForTesting
export const resetTikTokApi = () => {
  globals.window.tiktokEmbed = null; // eslint-disable-line fp/no-mutation
  // eslint-disable-next-line fp/no-mutation
  loadingApiPromise = null;
};

export const loadTikTokApi = async () => {
  if (!loadingApiPromise) {
    // eslint-disable-next-line fp/no-mutation
    loadingApiPromise = retrieveTikTokJsScript();
  }
  return loadingApiPromise;
};
