import { css, cx } from '@linaria/core';

import { noop } from '../../utils/commons';
import { colors } from '../../utils/css';
import { StyledA11yLinkTargetMarker as A11yLinkTargetMarker } from '../a11y/A11yLinkTargetMarker.jsx';
import { ids } from '../../utils/staticElementIds';

export const ContentPageLayout = ({
  DetailZoneFn = noop,
  AlternatingColorZoneConfig = {
    compFn: noop,
    isBgColorOnEveryEven: false,
  },
  className,
}) => {
  const DetailZone = DetailZoneFn();
  const { compFn: AlternatingZoneFn, isBgColorOnEveryEven } = AlternatingColorZoneConfig;
  const AlternatingZone = AlternatingZoneFn();

  return (
    <A11yLinkTargetMarker
      className={cx(contentPageLayoutClass, 'container-fluid', className)}
      id={ids.quickNav.mainContent}
    >
      {DetailZone && <section className="row">
        <div className="detail-zone col">
          {DetailZone}
        </div>
      </section>}
      {AlternatingZone && <section className="row">
        <div className={cx(`alternating-zone col ${isBgColorOnEveryEven ? 'even-alternating' : 'odd-alternating'}`)}>
          {AlternatingZone}
        </div>
      </section>}
    </A11yLinkTargetMarker>
  );
};

export const cssVariables = {
  basicBgColor: '--content-page-layout-basic-color',
  alternativeBgColor: '--content-page-layout-alternative-color',
};

export const contentPageLayoutClass = css`
  .detail-zone,  .alternating-zone {
    padding: 0;
  }

  .alternating-zone {
    &.even-alternating > {
      *:nth-child(2n+2) {
        background: var(${cssVariables.alternativeBgColor}, ${colors.BLUE_GREY_01});
      }
      *:nth-child(2n+1) {
        background: var(${cssVariables.basicBgColor}, transparent);
      }
    }

    &.odd-alternating > {
      *:nth-child(2n+1) {
        background: var(${cssVariables.alternativeBgColor}, ${colors.BLUE_GREY_01});
      }
      *:nth-child(2n+2) {
        background: var(${cssVariables.basicBgColor}, transparent);
      }
    }
  }
`;

ContentPageLayout.darkStyles = css`
  ${cssVariables.basicBgColor}: ${colors.DARK_BLUE_GREY_01};
  ${cssVariables.alternativeBgColor}: ${colors.DARK_BLUE_GREY_02};
`;
