import classnames from 'classnames';
import { css } from '@linaria/core';

import { mediaMin, colors } from '../../../../utils/css';

import { StyledChevronOpenClose as ChevronOpenClose } from '../../../icons';
import { useToggle } from '../../../hooks/useToggle';
import { useTranslation } from '../../../hooks/useTranslation.jsx';
import { ImageBasicCaption } from '../../../ImageBasicCaption.jsx';
import { ButtonWithIconOnly } from '../../../buttons/Button';

export const MoveUpImageCaption = ({
  caption = '',
  licenserSupplement,
  className,
}) => {
  const [isOpen, { toggleOnClick: toggleIsOpen }] = useToggle();
  const buttonTitle = useTranslation(`general.image.caption.label.${isOpen ? 'close' : 'open'}`);

  if (!caption && !licenserSupplement) {
    return null;
  }
  return (
    <div className={className}>
      <div className={detailsIconStyles}>
        <ButtonWithIconOnly className={ButtonWithIconOnly.darkStyles} onClick={toggleIsOpen} aria-expanded={isOpen} title={buttonTitle}>
          <ChevronOpenClose pointsDown={!isOpen}/>
        </ButtonWithIconOnly>
      </div>
      <div className={classnames(moveUpImageCaptionDetailsStyles, {
        opened: isOpen,
        closed: !isOpen,
        'short-animation': caption.length < 500,
      })}
      >
        <ImageBasicCaption caption={caption} licenserSupplement={licenserSupplement} />
      </div>
    </div>
  );
};

export const darkMoveUpImageCaptionStyles = ImageBasicCaption.darkStyles;

// TODO linaria-next check why the heck we do seperate this?!
export const moveUpImageCaptionDetailsStyles = css`
    overflow: hidden;
    will-change: transform, max-height;
    &.opened {
      max-height: 105rem;
      transition: max-height 2s cubic-bezier(1, 1, 1, 0);
      &.short-animation {
        transition: max-height 2s cubic-bezier(0.3, 0.1, 1, 1);
      }
    }
    &.closed {
      max-height: 0rem;
      transition: max-height 1s cubic-bezier(0, 1, 0, 1);
    }
  ${mediaMin.md`
    figcaption {
      padding: 10px 40px;
    }
  `}
  ${mediaMin.lg`
    figcaption {
      padding: 10px 30px;
    }
  `}
`;

// TODO linaria-next check why the heck we do seperate this?!
export const detailsIconStyles = css`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 5px;

  [role=button] {
    width: 40px;
    height: 40px;
    svg {
      width: 20px;
      height: 20px;
      filter: drop-shadow(0 0 3px ${colors.BLACK}) drop-shadow(0 0 6px ${colors.BLACK}) drop-shadow(0 0 9px ${colors.BLACK});
    }
    &:active {
      svg {
        filter: unset;
      }
    }
  }
`;

export const StyledMoveUpImageCaption = MoveUpImageCaption;
