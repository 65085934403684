import { useEffect, useState } from 'react';

import { loadTikTokApi } from './api';

export const useLoadTikTokApiEffect = ({ inView }) => {
  const [tikTokApi, setTikTokApi] = useState({ tiktokEmbed: null });

  useEffect(() => {
    const loadAndSaveTikTokApi = async () => {
      const { tiktokEmbed } = await loadTikTokApi();
      setTikTokApi({ tiktokEmbed });
    };
    if (inView && !tikTokApi.tiktokEmbed) {
      loadAndSaveTikTokApi();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  return tikTokApi;
};

const tikTokVideoIdRegex = /video\/(\d+)(\/|\?|$)/;

export const extractTikTokVideoIdFromUrl = url => {
  const match = url.match(tikTokVideoIdRegex);

  return match ? match[1] : null;
};
