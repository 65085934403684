import { styled } from '@linaria/react';
import { cx } from '@linaria/core';
import gql from 'graphql-tag';
import { darkTeaserTextStyles, StyledTeaserText as TeaserText } from '../TeaserText.jsx';
import { makeMemoFragment } from '../../utils/graphql';
import { StyledDetailHeaderHeadline as DetailHeaderHeadline } from '../DetailHeaderHeadline.jsx';

export const programDetailHeaderFragment = makeMemoFragment({
  name: 'ProgramDetailHeader',
  fragment() {
    return gql`fragment ${this.name} on UnifiedProgram {
        title
        teaser
      }
    `;
  },
});

export const ProgramDetailHeader = ({ program, className }) => (
  <div className={className}>
    <DetailHeaderHeadline className={DetailHeaderHeadline.darkStyles}>{program.title}</DetailHeaderHeadline>
    {program.teaser && (
      <TeaserText className={cx('teaser', darkTeaserTextStyles)}>{program.teaser}</TeaserText>
    )}
  </div>
);

export const StyledProgramDetailHeader = styled(ProgramDetailHeader)`
  .teaser {
    margin-bottom: 2px;
  }
`;
