import { StyledFlyingFocus as FlyingFocus } from '../FlyingFocus';
import { BodyScrollContext } from '../navigation/BodyScrollContext.jsx';
import { WindowInnerWidthContextProvider } from '../WindowInnerWidthContext';
import { MobileAppContextProvider } from '../hooks/mobileAppContext';
import { useGptEffect } from '../../utils/gpt';
import { SilentRedirectContextProvider } from '../hooks/silentRedirectContext.jsx';
import { GlobalComponents } from './GlobalComponents';
import { LimitedPageLayoutWithTheme } from './LimitedPageLayout.jsx';

export const HooksDependentOnMobileAppContext = () => {
  useGptEffect();
  return null;
};

export const Page = ({ children }) => (
  <>
    <GlobalComponents/>
    <SilentRedirectContextProvider>
      <MobileAppContextProvider>
        <BodyScrollContext>
          <WindowInnerWidthContextProvider>
            <LimitedPageLayoutWithTheme>
              {children}
            </LimitedPageLayoutWithTheme>
          </WindowInnerWidthContextProvider>
        </BodyScrollContext>
        <HooksDependentOnMobileAppContext/>
      </MobileAppContextProvider>
    </SilentRedirectContextProvider>
    <FlyingFocus />
  </>
);
