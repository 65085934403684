import gql from 'graphql-tag';
import { css, cx } from '@linaria/core';

import { makeMemoFragment } from '../../utils/graphql';
import { headerFragmentFactory, StyledHeader as Header } from '../Header';
import { footerFragmentFactory, StyledFooter as Footer } from '../Footer';
import { PageHeaderMetadata, pageHeaderMetadataFragment } from '../PageHeaderMetadata.jsx';
import { ImageSharingMetadata, imageSharingMetadataFragment } from '../ImageSharingMetadata.jsx';
import { homepageLinkFragment, StyledHomepageLink as HomepageLink, darkHomepageLinkStyles } from '../buttons/HomepageLink.jsx';
import { NotFoundPage } from '../../pages/NotFoundPage.jsx';
import { OnDetailPageContext } from '../PageEmbeddingContext.jsx';
import { StyledQuickNavigationList as QuickNavigationList } from '../a11y/QuickNavigationList.jsx';
import { BrowserOnlyBreakingBanner } from '../BreakingBanner';
import { ContentPageLayout } from '../layouts/ContentPageLayout.jsx';
import { programRecommendedZoneFragment, StyledProgramRecommendedZone as ProgramRecommendedZone } from './ProgramRecommendedZone.jsx';
import { programDetailsFragment as programDetailsInnerFragment, StyledProgramDetails as ProgramDetails } from './ProgramDetails.jsx';
import { colors } from '../../utils/css';

export const programDetailsWithFallbackFragment = makeMemoFragment({
  name: 'ProgramDetailsWithFallback',
  fragment() {
    const headerFragment = headerFragmentFactory();
    const footerFragment = footerFragmentFactory();
    return gql`fragment ${this.name} on UnifiedProgram {
        autoDelivery
        visibleInTvApps
        imitateProgram
        ...${programDetailsInnerFragment.name}
        ...${homepageLinkFragment.name}
        ...${headerFragment.name}
        ...${footerFragment.name}
        ...${programRecommendedZoneFragment.name}
        ...${pageHeaderMetadataFragment.name}
        ...${imageSharingMetadataFragment.name}
      }
      ${programDetailsInnerFragment.fragment()}
      ${homepageLinkFragment.fragment()}
      ${headerFragment.fragment()}
      ${footerFragment.fragment()}
      ${programRecommendedZoneFragment.fragment()}
      ${pageHeaderMetadataFragment.fragment()}
      ${imageSharingMetadataFragment.fragment()}
     `;
  },
});

export const ProgramDetailsWithFallback = ({
  content, className,
}) => {
  if (
    content.autoDelivery !== 1 ||
    !(content.imitateProgram || content.visibleInTvApps)
  ) {
    return (
      <NotFoundPage/>
    );
  }

  return (
    <OnDetailPageContext>
      <PageHeaderMetadata content={content}/>
      <ImageSharingMetadata content={content} />
      <QuickNavigationList content={content} />
      <Header content={content} />
      <div className={cx(programDetailsWithFallbackClass, className)}>
        <BrowserOnlyBreakingBanner />
        <ContentPageLayout
          className={ContentPageLayout.darkStyles}
          DetailZoneFn={
            () => <ProgramDetails program={content}/>
          }
          AlternatingColorZoneConfig={{
            compFn: () => <ProgramRecommendedZone content={content}/>,
          }}
        />
        <HomepageLink className={cx('homepage-link', darkHomepageLinkStyles)} content={content}/>
      </div>
      <Footer content={content} />
    </OnDetailPageContext>
  );
};

export const programDetailsWithFallbackClass = css`
  background-color: ${colors.DARK_BLUE_GREY_01};
`;
