import { useCallback, useEffect, useState } from 'react';
import globals from '../../utils/globals';
import { isServer } from '../../utils/ssr';

const CUSTOM_LS_EVENT_NAME = 'local-storage';

// @VisibleForTesting
export const readValue = (key, isPrimitive) => {
  if (isServer()) {
    return null;
  }
  const lsValue = globals.localStorage.getItem(key);
  if (isPrimitive) {
    return lsValue;
  }
  try {
    return JSON.parse(lsValue);
  } catch (e) {
    return null;
  }
};

export const useLocalStorage = (key, { isPrimitive = false } = {}) => {
  const [storedValue, setStoredValue] = useState(isPrimitive ? readValue(key, isPrimitive) : null);

  const setValue = useCallback(value => {
    if (!isServer()) {
      globals.localStorage.setItem(key, isPrimitive ? value : JSON.stringify(value));
      setStoredValue(value);

      // We dispatch a custom event so every useLocalStorage hook are notified
      globals.window.dispatchEvent(new Event(CUSTOM_LS_EVENT_NAME));
    }
  }, [key, isPrimitive]);

  const removeValue = useCallback(() => {
    if (!isServer()) {
      globals.localStorage.removeItem(key);
      setStoredValue(null);

      // We dispatch a custom event so every useLocalStorage hook are notified
      globals.window.dispatchEvent(new Event(CUSTOM_LS_EVENT_NAME));
    }
  }, [key]);

  useEffect(() => {
    const handleStorageChange = () => setStoredValue(readValue(key, isPrimitive));

    handleStorageChange();

    // this only works for other documents, not the current one
    globals.window.addEventListener('storage', handleStorageChange);
    // this is a custom event, triggered in writeValueToLocalStorage
    globals.window.addEventListener(CUSTOM_LS_EVENT_NAME, handleStorageChange);

    return () => {
      globals.window.removeEventListener('storage', handleStorageChange);
      globals.window.removeEventListener(CUSTOM_LS_EVENT_NAME, handleStorageChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [storedValue, setValue, removeValue];
};
