import gql from 'graphql-tag';
import { InView } from 'react-intersection-observer';
import { cx } from '@linaria/core';

import { StyledCurrentNews as CurrentNews, currentNewsFragment, currentNewsConfigFragment } from '../ContentBlocks/CurrentNews';
import { TopStoryInterfaceAdapter, topStoryAdapterFragment } from './adapters/TopStoryInterfaceAdapter.jsx';
import { StyledStoriesHub as StoriesHub, storiesHubFragment, storiesHubGeoRegionFragment } from '../ContentBlocks/Stories/StoriesHub';
import { StyledStoriesList as StoriesList, storiesListFragment, storiesListConfigFragment } from '../ContentBlocks/Stories/StoriesList';
import { StyledDwRecommendsTopics as DwRecommendsTopics, dwRecommendsTopicsFragment } from '../ContentBlocks/DWRecommends';
import { StyledPanoramaItems as PanoramaItems, panoramaItemsFragment } from '../ContentBlocks/Panorama';
import { geoRegionHeadlineFn, geoRegionBottomLinkFn } from '../ContentBlocks/Stories/HighPriorityStories';
import { StyledOpinion as Opinion, opinionFragment } from '../ContentBlocks/Opinion';
import { StyledWidgetContentBlock as WidgetContentBlock, widgetContentBlockFragment } from '../ContentBlocks/WidgetContentBlock.jsx';
import { StyledTopicCluster as TopicCluster, topicClusterFragment } from '../ContentBlocks/TopicCluster';
import {
  StyledTopicClusterAutomatic as TopicClusterAutomatic,
  topicClusterAutomaticFragment,
  topicClusterAutomaticConfigFragment,
} from '../ContentBlocks/TopicClusterAutomatic';
import {
  StyledTopicClusterAV as TopicClusterAV, topicClusterAVConfigFragment, topicClusterAVFragment,
} from '../ContentBlocks/TopicClusterAV';
import { StyledCrossPromotion as CrossPromotion, crossPromotionFragment } from '../ContentBlocks/CrossPromotion';
import { StyledAVShowPlayer as AVShowPlayer, avShowPlayerFragment } from '../ContentBlocks/AVShowPlayer';
import { StyledBreakingNews as BreakingNews, breakingNewsFragment } from '../ContentBlocks/BreakingNews';
import { StyledAVCarousel as AVCarousel, avCarouselFragment } from '../ContentBlocks/AVCarousel';
import { StyledFeaturedAV as FeaturedAV, featuredAVFragment } from '../ContentBlocks/FeaturedAV/FeaturedAV.jsx';
import { StyledManualContentList as ManualContentList, manualContentListConfigFragment, manualContentListFragment } from '../ContentBlocks/ManualContentList';
import { AllProgramsTeaserInterfaceAdapter, allProgramsTeaserAdapterFragment } from '../ContentBlocks/AllProgramsTeaser/AllProgramsTeaserInterfaceAdapter.jsx';

import { isValidList } from '../../utils/contentUtils';
import { noop, toKebabCase } from '../../utils/commons';
import { useTopOffsetHeaderThreshold } from '../hooks/useTopOffsetHeaderThreshold';
import { cocoAdFragment, StyledCoCoAd as CoCoAd } from './CoCoAd.jsx';
import { isTopCoCoCo } from './coCoCoDetector';
import { videoFragment } from '../Video';
import { audioFragment } from '../Audio';
import { makeMemoFragment } from '../../utils/graphql';
import { isProgramInfoSpace } from '../zones/zoneUtils';

import {
  AVCarouselGlobalTopicVideoAdapter,
  avCarouselGlobalTopicVideoAdapterConfigurationFragment,
  avCarouselGlobalTopicVideoAdapterFragment,
} from '../ContentBlocks/AVCarousel/AVCarouselGlobalTopicVideoAdapter.jsx';
import {
  AVCarouselProfileTopicVideoAdapter,
  avCarouselProfileTopicVideoAdapterConfigurationFragment,
  avCarouselProfileTopicVideoAdapterFragment,
} from '../ContentBlocks/AVCarousel/AVCarouselProfileTopicVideoAdapter.jsx';
import {
  AVCarouselRegionVideoAdapter, avCarouselRegionVideoAdapterConfigurationFragment,
  avCarouselRegionVideoAdapterFragment,
} from '../ContentBlocks/AVCarousel/AVCarouselRegionVideoAdapter.jsx';
import { ContentPageLayout } from '../layouts/ContentPageLayout.jsx';
import { darkCoCoComponentBackgroundSelectorStyles } from './CoCoComponentBackgroundSelector.jsx';
import { useHeaderColorToggle } from '../hooks/useHeaderColorToggle';
import {
  AVCarouselManualTopicVideoAdapter,
  avCarouselManualTopicVideoAdapterFragment,
} from '../ContentBlocks/AVCarousel/AVCarouselManualTopicVideoAdapter.jsx';
import { useFeatureFlag } from '../hooks/useFeatureFlag/useFeatureFlag';

const templates = {
  CurrentNews: {
    cocoCmp: CurrentNews,
    fragment: currentNewsFragment,
    cocoConfigFragment: currentNewsConfigFragment,
  },
  TopStory: {
    cocoCmp: TopStoryInterfaceAdapter,
    fragment: topStoryAdapterFragment,
  },
  Stories: {
    cocoCmp: StoriesList,
    fragment: storiesListFragment,
    cocoConfigFragment: storiesListConfigFragment,
  },
  GeoLinkHubStories: {
    cocoCmp: StoriesHub,
    fragment: storiesHubGeoRegionFragment,
    includesMultipleConfigs: true,
  },
  Hub: {
    cocoCmp: StoriesHub,
    fragment: storiesHubFragment,
    includesMultipleConfigs: true,
  },
  DwRecommends: {
    cocoCmp: DwRecommendsTopics,
    fragment: dwRecommendsTopicsFragment,
    extraProps: {
      headlineTranslation: 'content_block.dw_recommends.headline',
    },
  },
  Panorama: {
    cocoCmp: PanoramaItems,
    fragment: panoramaItemsFragment,
    extraProps: {
      headlineTranslation: 'content_block.panorama.headline',
    },
  },
  Opinion: {
    cocoCmp: Opinion,
    fragment: opinionFragment,
    extraProps: {
      headlineTranslation: 'content_block.opinion.headline',
    },
  },
  Av: {
    cocoCmp: AVShowPlayer,
    fragment: avShowPlayerFragment,
  },
  AvCarousel: {
    cocoCmp: AVCarousel,
    fragment: avCarouselFragment,
  },
};

const cocoCmpTypes = {
  NEWS: {
    type: 'NEWS',
    ...templates.CurrentNews,
  },
  NEWS_THEMATIC_FOCUS: {
    type: 'NEWS_THEMATIC_FOCUS',
    ...templates.CurrentNews,
  },
  ATS_NEWS: {
    type: 'ATS_NEWS',
    ...templates.CurrentNews,
  },
  NEWS_REGION: {
    type: 'NEWS_REGION',
    ...templates.CurrentNews,
  },
  NEWS_COUNTRY: {
    type: 'NEWS_COUNTRY',
    ...templates.CurrentNews,
  },
  TOP_STORY: {
    type: 'TOP_STORY',
    ...templates.TopStory,
  },
  TOP_STORY_THEMATIC_FOCUS: {
    type: 'TOP_STORY_THEMATIC_FOCUS',
    ...templates.TopStory,
  },
  TOP_STORY_COUNTRY: {
    type: 'TOP_STORY_COUNTRY',
    ...templates.TopStory,
  },
  TOP_STORY_REGION: {
    type: 'TOP_STORY_REGION',
    ...templates.TopStory,
  },
  ATS_TOP_STORY: {
    type: 'ATS_TOP_STORY',
    ...templates.TopStory,
  },
  STORIES_THEMATIC_FOCUS: {
    type: 'STORIES_THEMATIC_FOCUS',
    ...templates.Stories,
    extraProps: {
      headlineTranslation: 'content_block.stories_thematic_focus.headline',
    },
  },
  ATS_STORIES_LIST: {
    type: 'ATS_STORIES_LIST',
    ...templates.Stories,
    extraProps: {
      headlineTranslation: 'content_block.stories_thematic_focus.headline',
    },
  },
  RELATED_STORIES_THEMATIC_FOCUS: {
    type: 'RELATED_STORIES_THEMATIC_FOCUS',
    ...templates.Stories,
    extraProps: {
      headlineTranslation: 'content_block.related_stories_thematic_focus.headline',
    },
  },
  STORIES_LIST_REGION: {
    type: 'STORIES_LIST_REGION',
    ...templates.Stories,
    extraProps: {
      headlineTranslation: 'content_block.stories_region.headline',
    },
  },
  STORIES_LIST_COUNTRY: {
    type: 'STORIES_LIST_COUNTRY',
    ...templates.Stories,
    extraProps: {
      headlineTranslation: 'content_block.stories_country.headline',
    },
  },
  HIGH_PRIORITY_STORIES: {
    type: 'HIGH_PRIORITY_STORIES',
    ...templates.GeoLinkHubStories,
    extraProps: {
      headlineTranslation: 'content_block.high_priority_stories.headline',
      hubHeadlineFn: geoRegionHeadlineFn,
      hubBottomElementFn: geoRegionBottomLinkFn,
    },
  },
  HIGH_PRIORITY_STORIES_LIST: {
    type: 'HIGH_PRIORITY_STORIES_LIST',
    ...templates.DwRecommends,
    extraProps: {
      headlineTranslation: 'content_block.high_priority_stories.headline',
    },
  },
  HUB_THEMATIC_FOCUS: {
    type: 'HUB_THEMATIC_FOCUS',
    ...templates.Hub,
    extraProps: {
      headlineTranslation: 'content_block.hub_thematic_focus.headline',
    },
  },
  HUB_REGION: {
    type: 'HUB_REGION',
    ...templates.GeoLinkHubStories,
    extraProps: {
      headlineTranslation: 'content_block.hub_region.headline',
      hubHeadlineFn: geoRegionHeadlineFn,
      hubBottomElementFn: geoRegionBottomLinkFn,
    },
  },
  DW_RECOMMENDS: {
    type: 'DW_RECOMMENDS',
    ...templates.DwRecommends,
  },
  DW_RECOMMENDS_THEMATIC_FOCUS: {
    type: 'DW_RECOMMENDS_THEMATIC_FOCUS',
    ...templates.DwRecommends,
  },
  DW_RECOMMENDS_REGION: {
    type: 'DW_RECOMMENDS_REGION',
    ...templates.DwRecommends,
  },
  DW_RECOMMENDS_COUNTRY: {
    type: 'DW_RECOMMENDS_COUNTRY',
    ...templates.DwRecommends,
  },
  ATS_DW_RECOMMENDS: {
    type: 'ATS_DW_RECOMMENDS',
    ...templates.DwRecommends,
  },
  PANORAMA: {
    type: 'PANORAMA',
    ...templates.Panorama,
  },
  PANORAMA_THEMATIC_FOCUS: {
    type: 'PANORAMA_THEMATIC_FOCUS',
    ...templates.Panorama,
  },
  PANORAMA_REGION: {
    type: 'PANORAMA_REGION',
    ...templates.Panorama,
  },
  PANORAMA_COUNTRY: {
    type: 'PANORAMA_COUNTRY',
    ...templates.Panorama,
  },
  MOST_CLICKED: {
    type: 'MOST_CLICKED',
    ...templates.Panorama,
    extraProps: {
      headlineTranslation: 'content_block.most_clicked.headline',
    },
  },
  OPINION: {
    type: 'OPINION',
    ...templates.Opinion,
  },
  OPINION_THEMATIC_FOCUS: {
    type: 'OPINION_THEMATIC_FOCUS',
    ...templates.Opinion,
  },
  OPINION_COUNTRY: {
    type: 'OPINION_COUNTRY',
    ...templates.Opinion,
  },
  OPINION_REGION: {
    type: 'OPINION_REGION',
    ...templates.Opinion,
  },
  ATS_OPINION: {
    type: 'ATS_OPINION',
    ...templates.Opinion,
  },
  WIDGET_WEBAPP: {
    type: 'WIDGET_WEBAPP',
    cocoCmp: WidgetContentBlock,
    fragment: widgetContentBlockFragment,
  },
  DOSSIER: {
    type: 'DOSSIER',
    cocoCmp: TopicCluster,
    fragment: topicClusterFragment,
  },
  CROSS_PROMOTION: {
    type: 'CROSS_PROMOTION',
    cocoCmp: CrossPromotion,
    fragment: crossPromotionFragment,
  },
  ATS_TOPIC_CLUSTER: {
    type: 'ATS_TOPIC_CLUSTER',
    cocoCmp: TopicClusterAutomatic,
    fragment: topicClusterAutomaticFragment,
    cocoConfigFragment: topicClusterAutomaticConfigFragment,
  },
  AV_NEWS: {
    type: 'AV_NEWS',
    ...templates.Av,
    extraProps: {
      isAVNews: true,
    },
  },
  AV_MAGAZINE: {
    type: 'AV_MAGAZINE',
    ...templates.Av,
  },
  NEWS_VIDEO: {
    type: 'NEWS_VIDEO',
    ...templates.AvCarousel,
    extraProps: {
      headlineTranslation: 'content_block.news_videos.headline',
    },
  },
  REPORTS_VIDEO: {
    type: 'REPORTS_VIDEO',
    ...templates.AvCarousel,
    extraProps: {
      headlineTranslation: 'content_block.reports_videos.headline',
    },
  },
  DW_RECOMMENDS_VIDEO: {
    type: 'DW_RECOMMENDS_VIDEO',
    ...templates.AvCarousel,
    extraProps: {
      headlineTranslation: 'content_block.dw_recommends.headline',
    },
  },
  FULL_SHOWS_VIDEO: {
    type: 'FULL_SHOWS_VIDEO',
    ...templates.AvCarousel,
    extraProps: {
      headlineTranslation: 'content_block.full_shows_videos.headline',
    },
  },
  PANORAMA_VIDEO: {
    type: 'PANORAMA_VIDEO',
    ...templates.AvCarousel,
    extraProps: {
      headlineTranslation: 'content_block.panorama.headline',
    },
  },
  TOPIC_CLUSTER_VIDEO: {
    type: 'TOPIC_CLUSTER_VIDEO',
    cocoCmp: TopicClusterAV,
    fragment: topicClusterAVFragment,
    cocoConfigFragment: topicClusterAVConfigFragment,
  },
  FEATURED_VIDEO: {
    type: 'FEATURED_VIDEO',
    fragment: featuredAVFragment,
    cocoCmp: FeaturedAV,
    extraProps: {
      headlineTranslation: 'content_block.featured_video.headline',
    },
  },
  FEATURED_AUDIO: {
    type: 'FEATURED_AUDIO',
    fragment: featuredAVFragment,
    cocoCmp: FeaturedAV,
    extraProps: {
      headlineTranslation: 'content_block.featured_audio.headline',
    },
  },
  NEWS_AUDIO: {
    type: 'NEWS_AUDIO',
    ...templates.AvCarousel,
    extraProps: {
      headlineTranslation: 'content_block.news_audios.headline',
    },
  },
  REPORTS_AND_SHOWS_AUDIO: {
    type: 'REPORTS_AND_SHOWS_AUDIO',
    ...templates.AvCarousel,
    extraProps: {
      headlineTranslation: 'content_block.reports_and_shows_audios.headline',
    },
  },
  MANUAL_CONTENTS: {
    type: 'MANUAL_CONTENTS',
    fragment: manualContentListFragment,
    cocoConfigFragment: manualContentListConfigFragment,
    cocoCmp: ManualContentList,
    extraProps: {
      headlineTranslation: 'content_block.stories_thematic_focus.headline',
    },
  },
  BREAKING_NEWS_WEBAPP: {
    type: 'BREAKING_NEWS_WEBAPP',
    cocoCmp: BreakingNews,
    fragment: breakingNewsFragment,
  },
  C_All_PROGRAMS_OVERVIEW: {
    type: 'C_All_PROGRAMS_OVERVIEW',
    cocoCmp: AllProgramsTeaserInterfaceAdapter,
    fragment: allProgramsTeaserAdapterFragment,
  },
  GLOBAL_TOPIC_VIDEO: {
    type: 'GLOBAL_TOPIC_VIDEO',
    cocoCmp: AVCarouselGlobalTopicVideoAdapter,
    fragment: avCarouselGlobalTopicVideoAdapterFragment,
    cocoConfigFragment: avCarouselGlobalTopicVideoAdapterConfigurationFragment,
  },
  PROFILE_TOPIC_VIDEO: {
    type: 'PROFILE_TOPIC_VIDEO',
    cocoCmp: AVCarouselProfileTopicVideoAdapter,
    fragment: avCarouselProfileTopicVideoAdapterFragment,
    cocoConfigFragment: avCarouselProfileTopicVideoAdapterConfigurationFragment,
  },
  MANUAL_TOPIC_VIDEO: {
    type: 'MANUAL_TOPIC_VIDEO',
    cocoCmp: AVCarouselManualTopicVideoAdapter,
    fragment: avCarouselManualTopicVideoAdapterFragment,
  },
  REGION_VIDEO: {
    type: 'REGION_VIDEO',
    cocoCmp: AVCarouselRegionVideoAdapter,
    fragment: avCarouselRegionVideoAdapterFragment,
    cocoConfigFragment: avCarouselRegionVideoAdapterConfigurationFragment,
  },
};

const getCollectionName = cocoCmpConfig => (cocoCmpConfig.includesMultipleConfigs ? 'cocoContents' : 'contents');

const getDataRequirementsForCoCoComponents = () => Object.values(cocoCmpTypes).map(cocoCmpConfig => {
  const collectionFieldName = getCollectionName(cocoCmpConfig);

  return `${cocoCmpConfig.type.toLowerCase()}: compositionComponents(type: ${cocoCmpConfig.type}) {
    id
    type
    order
    ${cocoCmpConfig.cocoConfigFragment ? `...${cocoCmpConfig.cocoConfigFragment.name}` : ''}
    ${collectionFieldName} {
      ...${cocoCmpConfig.fragment.name}
    }
  }`;
}).join('\n');

export const coCoComponentFragment = makeMemoFragment({
  name: 'CoCoComponentSelector',
  fragment() {
    return gql`fragment ${this.name} on InformationSpace {
        ${getDataRequirementsForCoCoComponents()}
        ...${cocoAdFragment.name}
      }
      ${cocoAdFragment.fragment()}
      ${videoFragment.fragment()}
      ${audioFragment.fragment()}

      ${templates.CurrentNews.fragment.fragment()}
      ${templates.CurrentNews.cocoConfigFragment.fragment()}
      ${templates.TopStory.fragment.fragment()}
      ${templates.Stories.fragment.fragment()}
      ${templates.Stories.cocoConfigFragment.fragment()}
      ${templates.GeoLinkHubStories.fragment.fragment()}
      ${templates.Hub.fragment.fragment()}
      ${templates.DwRecommends.fragment.fragment()}
      ${templates.Panorama.fragment.fragment()}
      ${templates.Opinion.fragment.fragment()}
      ${templates.Av.fragment.fragment()}
      ${templates.AvCarousel.fragment.fragment()}
      ${cocoCmpTypes.REPORTS_AND_SHOWS_AUDIO.fragment.fragment()}
      ${cocoCmpTypes.WIDGET_WEBAPP.fragment.fragment()}
      ${cocoCmpTypes.DOSSIER.fragment.fragment()}
      ${cocoCmpTypes.CROSS_PROMOTION.fragment.fragment()}
      ${cocoCmpTypes.ATS_TOPIC_CLUSTER.fragment.fragment()}
      ${cocoCmpTypes.ATS_TOPIC_CLUSTER.cocoConfigFragment.fragment()}
      ${cocoCmpTypes.FEATURED_AUDIO.fragment.fragment()}
      ${cocoCmpTypes.FEATURED_VIDEO.fragment.fragment()}
      ${cocoCmpTypes.TOPIC_CLUSTER_VIDEO.fragment.fragment()}
      ${cocoCmpTypes.TOPIC_CLUSTER_VIDEO.cocoConfigFragment.fragment()}
      ${cocoCmpTypes.MANUAL_CONTENTS.fragment.fragment()}
      ${cocoCmpTypes.MANUAL_CONTENTS.cocoConfigFragment.fragment()}
      ${cocoCmpTypes.BREAKING_NEWS_WEBAPP.fragment.fragment()}
      ${cocoCmpTypes.C_All_PROGRAMS_OVERVIEW.fragment.fragment()}
      ${cocoCmpTypes.GLOBAL_TOPIC_VIDEO.fragment.fragment()}
      ${cocoCmpTypes.GLOBAL_TOPIC_VIDEO.cocoConfigFragment.fragment()}
      ${cocoCmpTypes.PROFILE_TOPIC_VIDEO.fragment.fragment()}
      ${cocoCmpTypes.PROFILE_TOPIC_VIDEO.cocoConfigFragment.fragment()}
      ${cocoCmpTypes.MANUAL_TOPIC_VIDEO.fragment.fragment()}
      ${cocoCmpTypes.REGION_VIDEO.fragment.fragment()}
      ${cocoCmpTypes.REGION_VIDEO.cocoConfigFragment.fragment()}
    `;
  },
});

export const getCoCoSectionId = compositionCmp => [toKebabCase(compositionCmp.type), compositionCmp.id].filter(v => !!v).join('-');

export const SingleCoCoComponentSelector = ({ compositionComp }) => {
  const cocoConf = cocoCmpTypes[compositionComp.type];
  const contents = compositionComp[getCollectionName(cocoConf)];

  const { cocoCmp: ContentBlockTag, extraProps = {} } = cocoConf;
  return (
    <ContentBlockTag
      contents={contents}
      configuration={compositionComp.configuration}
      className={toKebabCase(compositionComp.type)}
      pageSectionId={getCoCoSectionId(compositionComp)}
      {...extraProps}/>
  );
};

const hasCoCoContentsPredicate = compositionComp => {
  const cocoConf = cocoCmpTypes[compositionComp.type];
  const contents = compositionComp[getCollectionName(cocoConf)];
  return isValidList(contents);
};

// eslint-disable-next-line fp/no-mutating-methods
export const getCoCoCosWithContents = infoSpace => Object.values(cocoCmpTypes)
  .map(cocoCmpConfig => infoSpace[cocoCmpConfig.type.toLowerCase()])
  .reduce((acc, next) => [...acc, ...next], [])
  .filter(hasCoCoContentsPredicate)
  .sort((c1, c2) => c1.order - c2.order);

const darkCoCoComponentClassName = cx(
  ContentPageLayout.darkStyles,
  darkCoCoComponentBackgroundSelectorStyles,
);

export const CoCoComponentSelector = ({
  infoSpace, isFirst = false, bottomElementFn = noop,
}) => {
  const topOffsetHeaderThreshold = useTopOffsetHeaderThreshold();
  const compositionComponents = getCoCoCosWithContents(infoSpace);
  const bottomElement = bottomElementFn();
  const { onChangeInViewListenerForHeaderSwitch } = useHeaderColorToggle();

  const hasFeatureFlag = useFeatureFlag();

  return (
    compositionComponents.map((compositionComp, index) => {
      const isTopCoCo = isTopCoCoCo(compositionComp);

      const Container = (isFirst && index === 0) ? {
        Tag: InView,
        props: {
          rootMargin: topOffsetHeaderThreshold,
          onChange: onChangeInViewListenerForHeaderSwitch,
          as: isTopCoCo ? 'span' : 'div',
        },
      } : {
        Tag: isTopCoCo ? 'span' : 'div',
        props: {},
      };

      // TODO: Remove when most clicked is live
      if (compositionComp.type === 'MOST_CLICKED' && !hasFeatureFlag) {
        return null;
      }

      return (
        <Container.Tag key={compositionComp.id}
          className={cx(
            isTopCoCo
              ? 'basic-color'
              : 'auto-bg-color',
            isProgramInfoSpace(infoSpace) && darkCoCoComponentClassName,
          )}
          {...Container.props}
        >
          <SingleCoCoComponentSelector
            compositionComp={compositionComp}
          />
          { (index === compositionComponents.length - 1) && (
            <>
              {bottomElement}
              <CoCoAd infoSpace={infoSpace} compositionComponents={compositionComponents}/>
            </>
          )}
        </Container.Tag>
      );
    })
  );
};
