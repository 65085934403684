import { memo } from 'react';
import gql from 'graphql-tag';
import { cx } from '@linaria/core';

import { useImmediateParams, Redirect, useLocation } from '../dwouter';
import { StyledHeaderWithTheme as Header, headerFragmentFactory } from '../components/Header';
import { ContentPageLayout } from '../components/layouts/ContentPageLayout.jsx';
import { StyledFooter as Footer, footerFragmentFactory } from '../components/Footer';
import { StyledQuickNavigationList as QuickNavigationList } from '../components/a11y/QuickNavigationList.jsx';
import { TopStoryZone } from '../components/zones/TopStoryZone/index.jsx';
import { azIndexDetailsFragment, AzIndexDetails } from '../components/AzIndexDetails';
import { StyledCoCoPageHeadlineInHeader as PageHeadlineInHeader } from '../components/CoCo/CoCoPageHeadlineInHeader.jsx';
import { OnDetailPageContext } from '../components/PageEmbeddingContext.jsx';
import { useTranslation } from '../components/hooks/useTranslation.jsx';
import { BrowserOnlyBreakingBanner } from '../components/BreakingBanner';
import { PageWithData, pageWithDataFragment } from './PageWithData.jsx';
import { isValidList } from '../utils/contentUtils';
import { NotFoundPage } from './NotFoundPage.jsx';
import { PageHeaderMetadata, pageHeaderMetadataFragment } from '../components/PageHeaderMetadata.jsx';
import { withTheme } from '../components/higherOrderComponents/withTheme.jsx';
import { azIndexRegexp } from '../supportedUrls';

export const azIndexQuery = () => {
  const headerFragment = headerFragmentFactory();
  const footerFragment = footerFragmentFactory();
  return gql`
  query azIndexData($lang: Language!, $selectedTopicGroupId: String) {
    azIndex(lang: $lang, selectedTopicGroupId: $selectedTopicGroupId) {
      id: originId
      language
      pageHeadline
      isLive
      ampUrl
      ...${pageWithDataFragment.name}
      ...${headerFragment.name}
      ...${footerFragment.name}
      ...${pageHeaderMetadataFragment.name}
      ...${azIndexDetailsFragment.name}
    }
  }
  ${pageWithDataFragment.fragment()}
  ${headerFragment.fragment()}
  ${footerFragment.fragment()}
  ${azIndexDetailsFragment.fragment()}
  ${pageHeaderMetadataFragment.fragment()}
`;
};

const TopStoryZoneMemo = memo(TopStoryZone);

export const AzIndexPageNoQuery = ({
  azIndex, className,
}) => {
  const azIndexLabel = useTranslation('pages.topic_index.title');
  return (
    <OnDetailPageContext>
      <PageHeaderMetadata content={azIndex} />
      <QuickNavigationList content={azIndex} />
      <Header content={azIndex}>
        {({ isHeaderInAlternateState, isForcedDarkMode }) => (isHeaderInAlternateState
          ? (
            <PageHeadlineInHeader className={isForcedDarkMode && PageHeadlineInHeader.darkStyles} navigation={azIndex} />
          )
          : null
        )}
      </Header>
      <div className={className}>
        <BrowserOnlyBreakingBanner />
        <ContentPageLayout
          DetailZoneFn={
            () => <AzIndexDetails
              azIndexTopicGroups={azIndex.azTopicGroups}
              azIndexTopics={azIndex.azTopics}
              azIndexLabel={azIndexLabel}
            />
          }
          AlternatingColorZoneConfig={{
            compFn: () => <TopStoryZoneMemo content={azIndex}/>,
          }}
        />
      </div>
      <Footer content={azIndex} />
    </OnDetailPageContext>
  );
};

export const AzIndexPageNoQueryWithTheme = withTheme(AzIndexPageNoQuery)(
  cx(
    ContentPageLayout.darkStyles,
    AzIndexDetails.darkStyles,
  ),
);

export const AzIndexPage = () => {
  const { langCode, selectedTopicGroupId } = useImmediateParams({ currentPathRegexp: azIndexRegexp });
  const [{ pathname, search }] = useLocation();

  const queryDef = {
    path: `${langCode}/az-index/${selectedTopicGroupId}`,
    depts: [langCode, selectedTopicGroupId],
  };

  return (
    <PageWithData queryDef={queryDef} langBaseContentFn={data => data.azIndex}>
      {data => {
        if (!isValidList(data.azIndex.azTopicGroups)) {
          return (
            <NotFoundPage/>
          );
        }

        const knownTopicGroup = data.azIndex.azTopicGroups.find(group => group.id === selectedTopicGroupId?.toLowerCase());
        if (!selectedTopicGroupId || !knownTopicGroup) {
          return <Redirect to={{ pathname: data.azIndex.azTopicGroups[0].url, search }} />;
        }
        if (pathname !== knownTopicGroup.url) {
          return <Redirect to={{ pathname: knownTopicGroup.url, search }} />;
        }
        return (
          <AzIndexPageNoQueryWithTheme azIndex={data.azIndex} />
        );
      }
      }
    </PageWithData>
  );
};
