/* eslint-disable no-template-curly-in-string */
import {
  StyledSmBalatarin as SmBalatarin,
  StyledSmDouban as SmDouban,
  StyledSmEmail as SmEmail, StyledSmFacebook as SmFacebook, StyledSmFacebookMessenger as SmFacebookMessenger,
  StyledSmFlipboard as SmFlipboard, StyledSmLine as SmLine, StyledSmLinkedIn as SmLinkedIn,
  StyledSmMeneame as SmMeneame, StyledSmMoikrug as SmMoikrug, StyledSmOdnoklassniki as SmOdnoklassniki,
  StyledSmReddit as SmReddit, StyledSmSms as SmSms,
  StyledSmTelegram as SmTelegram, StyledSmTwitter as SmTwitter, StyledSmViber as SmViber,
  StyledSmVkontakte as SmVkontakte, StyledSmWechat as SmWechat,
  StyledSmWeibo as SmWeibo, StyledSmWhatsapp as SmWhatsapp, StyledSmYoutube as SmYoutube,
  StyledSmInstagram as SmInstagram,
  StyledSmSharechat as SmSharechat,
  StyledSmMoj as SmMoj,
  StyledSmTiktok as SmTiktok,
} from '../components/icons';

export const socialMediaToIcon = {
  facebook: SmFacebook,
  'fb-messenger-web': SmFacebookMessenger,
  'facebook-messenger': SmFacebookMessenger,
  'x-twitter': SmTwitter,
  balatarin: SmBalatarin,
  meneame: SmMeneame,
  linkedin: SmLinkedIn,
  reddit: SmReddit,
  weibo: SmWeibo,
  douban: SmDouban,
  vkontakte: SmVkontakte,
  vk: SmVkontakte,
  moikrug: SmMoikrug,
  email: SmEmail,
  sms: SmSms,
  odnoklassniki: SmOdnoklassniki,
  whatsapp: SmWhatsapp,
  'whatsapp-web': SmWhatsapp,
  telegram: SmTelegram,
  wechat: SmWechat,
  viber: SmViber,
  line: SmLine,
  flipboard: SmFlipboard,
  youtube: SmYoutube,
  instagram: SmInstagram,
  sharechat: SmSharechat,
  moj: SmMoj,
  tiktok: SmTiktok,
};
