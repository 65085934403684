import { resolutions } from '../../../utils/css';

export const getDisplayStyles = ({ slotConfigEntry, cssVariables }) => {
  const cssVariableToDisplayEntries = Object.entries(resolutions.min).map(([breakpointName]) => {
    const displayValue = getDisplayValueFor({ slotConfigEntry, breakpointName });
    return [cssVariables.display[breakpointName], displayValue];
  });
  return Object.fromEntries(cssVariableToDisplayEntries);
};

// @VisibleForTesting
export const getDisplayValueFor = ({ slotConfigEntry, breakpointName = 'xs' }) => {
  const minResolutionWidth = resolutions.min[breakpointName];
  const maxResolutionWidth = resolutions.max[breakpointName];

  if (maxResolutionWidth < slotConfigEntry.minWidth || minResolutionWidth > slotConfigEntry.maxWidth) {
    return 'none';
  }
  return 'block';
};
